<template>
  <div
    :style="colorOverrides"
    class="event-list-item"
    data-cy="event-list-item"
    :class="{
      active: displayAddButton,
    }"
    tabindex="0"
    @mouseenter="showAddButton"
    @mouseleave="resetState"
    @blur.prevent="handleBlur"
    @keyup.tab.prevent.stop="showAddButton"
  >
    <div class="left">
      <div
        class="date"
        :class="{ 'multi-day': isMultiDayEvent }"
      >
        <EventListDate
          :month="eventData.month"
          :start-date="eventData.start_at"
          :end-date="isMultiDayEvent ? null : eventData.end_at"
          :is-all-day="eventData.all_day"
        />
        <template v-if="isMultiDayEvent">
          <EventLeftArrowIcon class="date-arrow" />
          <EventListDate
            :is-multi="isMultiDayEvent"
            :month="eventData.month"
            :start-date="isMultiDayEvent ? null : eventData.start_at"
            :end-date="eventData.end_at"
            :is-all-day="eventData.all_day"
          />
        </template>
      </div>
      <div class="content">
        <button
          class="title"
          @click="viewEvent"
        >
          {{ eventData.title }}
        </button>
        <div
          v-linkify
          class="description"
          v-html="sanitizedEventDescription"
        />
        <div class="venue">
          {{ eventData.venue }}
        </div>
        <div class="tags">
          <EventListTag
            v-for="tag in eventTags"
            :key="tag"
            :display-text="tag"
            variant="pill"
          />
        </div>
        <div
          v-if="isMobile"
          class="right"
        >
          <button
            ref="itemButton"
            class="button"
            @click="showCalendarButtons"
            @keydown.tab.exact="handleButtonBlur"
          >
            <EventListButtons
              :show-calendar-buttons="displayCalendarButtons"
              :handle-back-button-click="handleBackButtonClick"
              :color-overrides="colorOverrides"
              :event-data="eventData"
            />
            <div
              v-show="!displayCalendarButtons"
              class="button-container"
            >
              <AddToCalendarIcon />
              <p class="button-text">
                {{ $t('events.addToCalendar') }}
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!isMobile"
      class="right"
    >
      <button
        v-if="displayAddButton"
        ref="itemButton"
        class="button"
        @click="showCalendarButtons"
        @keydown.tab.exact="handleButtonBlur"
      >
        <EventListButtons
          :show-calendar-buttons="displayCalendarButtons"
          :handle-back-button-click="handleBackButtonClick"
          :color-overrides="colorOverrides"
          :event-data="eventData"
        />
        <div
          v-show="!displayCalendarButtons"
          class="button-container"
        >
          <AddToCalendarIcon />
          <p class="button-text">
            {{ $t('events.addToCalendar') }}
          </p>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DOMPurify from 'dompurify';
import EventListDate from './EventListDate.vue';
import EventLeftArrowIcon from '../../icons/EventLeftArrowIcon';
import EventListTag from './EventListTag';
import AddToCalendarIcon from '../../icons/AddToCalendarIcon';
import EventListButtons from './EventListButtons.vue';
import { CALENDARS } from '../../../../../helpers/calendar.helper';
import screenWidthTracker from '../../../../pages/forms/components/mixins/screen-width-tracker';

export default {
  name: 'EventListItem',
  components: {
    AddToCalendarIcon,
    EventListTag,
    EventLeftArrowIcon,
    EventListDate,
    EventListButtons,
  },
  mixins: [screenWidthTracker],
  props: {
    eventData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      displayAddButton: false,
      displayCalendarButtons: false,
      CALENDARS,
      sanitizedEventDescription: '',
    };
  },
  computed: {
    ...mapState('events', ['sections']),
    ...mapGetters({ headingColor: 'getThemeHeadingColor', getCustomSections: 'getCustomSections' }),
    colorOverrides() {
      const color = this.headingColor;
      if (!color) {
        return {};
      }

      return {
        '--button-outline': color,
        // Primary
        '--button-primary-bg': `${color}1A`,
        '--button-primary-bg-hover': color,
        '--event-item-hover': `${color}1A`,
      };
    },
    isMultiDayEvent() {
      const startDate = this.eventData.start_at.split('T')[0];
      const endDate = this.eventData.end_at.split('T')[0];
      return startDate !== endDate;
    },
    eventTags() {
      const tags = [];
      if (this.sections.length > 1) {
        const section = this.getCustomSections.find((sec) => sec.id === this.eventData.custom_section_id);
        if (section) tags.push(section.name);
      }
      tags.push(...this.eventData.filter_name);
      return tags;
    },
    isMobile() {
      return this.screenSize < 960;
    },
  },
  created() {
    this.sanitizedEventDescription = DOMPurify.sanitize(this.eventData.description);
  },
  methods: {
    viewEvent() {
      return navigateTo({
        query: {
          id: this.eventData.id,
        },
      });
    },
    showAddButton() {
      if (this.isMobile) return;
      this.displayAddButton = true;
    },
    showCalendarButtons() {
      this.displayCalendarButtons = true;
    },
    resetState() {
      this.displayAddButton = false;
      this.displayCalendarButtons = false;
    },
    handleBackButtonClick() {
      this.displayCalendarButtons = false;
      this.$refs.itemButton.focus();
    },
    handleBlur(event) {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        this.resetState();
      }
    },
    handleButtonBlur() {
      if (this.displayCalendarButtons) return;
      this.resetState();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../global/scss/mixins/flex_mixin';
@import '../../../../global/scss/mixins/breakpoints';

$buttonMinHeight: 160px;
$buttonMinWidth: 136px;
$buttonMargin: 8px;
$sidesWidth: 192px;

.event-list-item {
  @include flex-column;
  border-bottom: 1px solid rgba(0, 73, 144, 0.1);
  padding: 0;
  justify-content: space-between;
  min-height: 192px;
  @media (min-width: $md) {
    @include flex-row;
  }

  &.active {
    background: rgba(0, 73, 144, 0.06);
  }

  &:hover {
    background: var(--event-item-hover);
  }

  &:first-of-type {
    margin-top: 32px;
  }

  .left {
    @include flex-row;
    flex: 1;
    padding: 24px 0;

    .date {
      @include flex-row;
      justify-content: left;
      align-items: flex-start;
      padding: 0 0 0 34px;
    }

    .content {
      @include flex-column;
      flex: 1;
    }
  }

  .right {
    display: none;
    @include flex-row;
    width: 151px;

    @media (min-width: $md) {
      @include flex-column;
      min-width: $buttonMinWidth + ($buttonMargin * 2);
    }
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 16px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    text-align: left;
  }
  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    word-break: break-word;
    :deep(a) {
      color: var(--links-color);
    }
  }
  .venue {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #747474;
  }
  .tags {
    margin-top: 16px;
  }
  .date-arrow {
    margin-top: 24px;
    @media (min-width: $lg-min-width) {
      margin: 24px 8px;
    }
  }

  .button {
    @include flex-row;
    justify-content: center;
    align-items: center;
    margin: 8px 0;
    background: rgba(0, 73, 144, 0.06);
    border-radius: 4px;
    border: none;
    min-height: 50px;
    min-width: 151px;
    cursor: pointer;
    padding: 8px;
    background: var(--event-item-hover);
    box-sizing: border-box;
    @media (min-width: $md) {
      @include flex-column;
      min-height: $buttonMinHeight;
      min-width: $buttonMinWidth;
      margin: auto;
    }

    .button-container {
      @include flex-row;
      justify-content: center;
      align-items: center;
      align-content: center;
      width: 100%;

      @media (min-width: $md) {
        @include flex-column;
        justify-content: stretch;
      }

      &.open {
        @include flex-column;
        justify-content: stretch;
      }

      & p.button-text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        text-align: center;
        letter-spacing: 0.4px;

        color: #333333;
      }
      .back-calendar-button:hover {
        color: #ffffff;
      }
    }

    .calendar-buttons {
      @include flex-column;
    }
  }
}
.event-list-item {
  @include screen('xs') {
    .left {
      .date {
        padding: 0 23px 0 12px;
        width: 142px;
      }
    }
  }
  @include screen('sm') {
    .left {
      .date {
        padding: 0 34px 0 34px;
        min-width: 142px;
      }
    }
  }
  @include screen('>=md') {
    .left {
      .date {
        padding: 0 0 0 34px;
        min-width: 192px;
      }
    }
  }
}
</style>
